.cases {
  visibility: hidden;
  height: 50vh;
  position: relative;

  .cases-row {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;

    @media all and (max-width: 768px) {
      flex-direction: column;
    }

    .case {
      position: relative;

      .case-details {
        width: 33.3333vw;
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px 32px;
        box-sizing: border-box;
        z-index: 3;
        position: relative;
        @media all and (max-width: 768px) {
          width: 100vw;
        }

        span {
          margin-top: 156px;
          font-size: 1.4rem;
          opacity: 0.8;
          color: var(--color-white);
          font-weight: 600;
          @media all and (max-width: 1366px) {
            font-size: 1.2rem;
          }
          @media all and (max-width: 1000px) {
            font-size: 1rem;
          }
          @media all and (max-width: 768px) {
            font-size: 1.2rem;
          }
          @media all and (max-width: 425px) {
            font-size: 1rem;
          }
        }
        h2 {
          font-size: 1.8rem;
          line-height: 3rem;
          width: 85%;
          margin-top: 16px;
          margin-bottom: 16px;
          color: var(--color-white);
          @media all and (max-width: 1366px) {
            font-size: 1.6rem;
            line-height: 2.7rem;
          }
          @media all and (max-width: 1000px) {
            font-size: 1.4rem;
            line-height: 2.5rem;
          }
          @media all and (max-width: 768px) {
            font-size: 1.8rem;
            line-height: 3rem;
          }
          @media all and (max-width: 425px) {
            font-size: 1.6rem;
            line-height: 2.7rem;
          }
        }
      }
      .case-image {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .gradient {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        opacity: 40%;
      }
    }
  }

  .prev-arrow {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    padding: 32px;
    @media all and (max-width: 768px) {
      display: none;
    }
  }

  .next-arrow {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;
    padding: 32px;

    @media all and (max-width: 768px) {
      display: none;
    }
  }
}
