.gallery-wrap {
  background-color: var(--color-green);
  position: relative;
}

.gallery-counter {
  position: absolute;
  top: 4%;
  left: 100px;
  z-index: 1;
  line-height: 16px;
  color: var(--color-white);
  font-weight: 600;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;

  .divider {
    content: "";
    background-color: var(--color-white);
    width: 6.25vw;
    margin: 7px 10px;
    height: 1px;
    display: inline-block;
  }
}

.gallery {
  height: 80vh;
  padding: 10vh 0;
  width: 400%;
  display: flex;
  flex-wrap: nowrap;

  .gallery-item {
    width: 100%;
    height: 100%;
    position: relative;
    will-change: transform;
  }

  .gallery-item-info {
    position: absolute;
    bottom: 10%;
    z-index: 1;
    transform: translateX(-20%);
    color: var(--color-white);
    @media all and (max-width: 768px) {
      transform: translateX(-5%);
    }

    .gallery-info-title {
      line-height: 6vw;
      font-family: "Bai Jamjuree";
      font-weight: 600;
      font-size: 6vw;
      -webkit-font-smoothing: antialiased;
    }
  }
  .gallery-item-wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 20vw 1fr 20vw;
    width: 100vw;
    @media all and (max-width: 768px) {
      grid-template-columns: 10vw 1fr 10vw;
    }

    &.is-reveal {
      .gallery-item-image {
        transform: scale(1);
        filter: none;
      }
    }
  }
  .gallery-item-image {
    background-size: cover;
    background-position: center;
    transform-origin: center;
    width: 100%;
    height: 100%;
    will-change: transform;
    transform: scale(0.7);
    transition: all 1.5s cubic-bezier(0.77, 0, 0.175, 1);
    filter: grayscale(100%) sepia(20%) brightness(80%);
  }
}
