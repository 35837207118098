.banner {
  background-color: var(--color-white);
  visibility: hidden;

  .row {
    flex-direction: column;
    justify-content: center;
    h2 {
      font-size: 3rem;
      line-height: 4.2rem;
      font-weight: 700;
      z-index: 10;
      color: var(--color-white);
      mix-blend-mode: difference;
      margin-top: 0;
      margin-bottom: 20px;
      @media all and (max-width: 768px), all and (max-height: 600px) {
        font-size: 2rem;
        line-height: 3rem;
      }
      @media all and (max-width: 600px) {
        font-size: 1.8rem;
        line-height: 2.6rem;
      }
      @media all and (max-width: 425px) {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
      @media all and (max-width: 375px) {
        font-size: 1.3rem;
        line-height: 2rem;
      }
      @media all and (max-width: 320px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }

      .line {
        margin-bottom: 8px;
        height: 56px;
        position: relative;
        overflow: hidden;
        @media all and (max-width: 768px), all and (max-height: 600px) {
          height: 48px;
        }
        @media all and (max-width: 425px) {
          height: 32px;
        }
        span {
          position: absolute;
        }
      }
    }
    .btn-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 2;

      .btn-text {
        color: var(--color-black);
        font-size: 1.6rem;
        font-weight: 600;
        margin-right: 16px;

        @media all and (max-width: 768px), all and (max-height: 600px) {
          font-size: 1.4rem;
        }
        @media all and (max-width: 600px) {
          font-size: 1.3rem;
        }
        @media all and (max-width: 425px) {
          font-size: 1.2rem;
        }
        @media all and (max-width: 375px) {
          font-size: 1.1rem;
        }
        @media all and (max-width: 320px) {
          font-size: 1rem;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
