.header {
  width: 100%;
  height: 100px;
  z-index: 110;
  visibility: hidden;
  @media all and (max-width: 768px), all and (max-height: 600px) {
    height: 80px;
  }

  .logo {
    height: 40px;
    cursor: pointer;
    @media all and (max-width: 768px), all and (max-height: 600px) {
      height: 30px;
    }
    @media all and (max-width: 600px) {
      height: 26px;
    }
    @media all and (max-width: 425px) {
      height: 23px;
    }
    @media all and (max-width: 375px) {
      height: 20px;
    }
    @media all and (max-width: 320px) {
      height: 18px;
    }
  }

  .nav-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 40px;
    cursor: pointer;
    @media all and (max-width: 768px), all and (max-height: 600px) {
      height: 30px;
      width: 40px;
    }
    @media all and (max-width: 425px) {
      height: 20px;
      width: 30px;
    }

    .hamburger-menu {
      width: 25px;
      height: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @media all and (max-width: 768px), all and (max-height: 600px) {
        width: 20px;
        height: 10px;
      }

      span {
        background: var(--color-black);
        height: 2px;
        width: 100%;
      }
    }
    .hamburger-menu-close {
      display: none;
      cursor: pointer;

      svg {
        @media all and (max-width: 768px), all and (max-height: 600px) {
          width: 56px;
        }

        @media all and (max-width: 425px) {
          width: 48px;
        }
      }
    }
  }
}
