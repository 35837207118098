.company-section {
  display: flex;
  flex-direction: row;
  padding-top: 5vh;
  padding-bottom: 20vh;

  .container {
    .row {
      @media all and (max-width: 768px) {
        padding: 0;
        display: flex;
        justify-content: center;
      }
      .company-left-layout {
        width: 30%;
        @media all and (max-width: 768px) {
          display: none;
        }

        img {
          width: 100%;
          height: 65%;
          object-fit: cover;
          object-position: right top;
          clip-path: inset(100% 0% 0% 0%);
          transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
        }
      }

      .company-mid-layout {
        width: 10%;
        @media all and (max-width: 768px) {
          display: none;
        }
      }

      .company-right-layout {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media all and (max-width: 768px) {
          width: 90%;
        }

        .text-container {
          h2 {
            font-size: 1.2rem;
            font-weight: 200;
            color: var(--color-black);
            @media all and (max-width: 1366px) {
              font-size: 1.1rem;
            }
            @media all and (max-width: 1150px) {
              font-size: 1rem;
            }
            @media all and (max-width: 1000px) {
              font-size: 0.9rem;
            }
            @media all and (max-width: 850px) {
              letter-spacing: -0.03rem;
            }
            @media all and (max-width: 425px) {
              font-size: 0.7rem;
            }
            &#first-h2 {
              padding-bottom: 1rem;
            }
            .company-line {
              margin-top: 8px;
              height: 1.2rem;
              position: relative;
              overflow: hidden;
              @media all and (max-width: 768px) {
                height: 1rem;
              }
              span {
                position: absolute;
              }
            }
          }
        }
        img {
          width: 100%;
          height: 40vh;
          margin-bottom: 5vh;
          margin-top: 10vh;
          object-fit: cover;
          object-position: right top;
          clip-path: inset(100% 0% 0% 0%);
          transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
          @media all and (max-width: 768px) {
            margin-top: 5vh;
          }
        }
      }
    }
  }
}
