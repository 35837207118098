.navigation {
  left: 0;
  top: -1px;
  width: 100%;
  height: 1px;
  z-index: 1;
  display: none;
  overflow: hidden;
  background: var(--color-green);
  @media all and (max-width: 768px) {
    flex-direction: column;
  }

  a {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 1px;
      display: block;
      margin-top: 5px;
      right: 0;
      background: var(--color-white);
      transition: 0.4s ease;
    }
    &:hover {
      &:after {
        width: 100%;
        left: 0;
        background: var(--color-white);
      }
    }
  }
  .nav-columns {
    display: flex;
    height: calc(50vh + 1px);
    padding: 0 32px;
    @media all and (max-width: 768px) {
      flex-direction: column;
      justify-content: space-around;
      height: calc(100vh - 80px + 1px);
    }
    @media all and (max-width: 425px) {
      padding: 0 16px;
    }

    .nav-column {
      width: 45%;
      padding: 32px 0;
      @media all and (max-width: 768px) {
        width: 100%;
        padding: 16px 0;
      }
      &:last-child {
        width: 55%;
        @media all and (max-width: 768px) {
          width: 100%;
        }
      }
      .nav-label {
        margin-bottom: 1.4rem;
        font-size: 1.4rem;
        color: var(--color-white);
        @media all and (max-width: 768px), all and (max-height: 600px) {
          font-size: 1.2rem;
          margin-bottom: 1.2rem;
        }
        @media all and (max-width: 425px) {
          font-size: 1rem;
          margin-bottom: 1rem;
        }
      }
      .nav-infos {
        padding: 0;
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .nav-info {
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 10px;
          @media all and (max-width: 425px) {
            margin-bottom: 5px;
          }
          .nav-info-label {
            font-weight: 600;
          }
          li {
            font-weight: 300;
            list-style: none;
            font-size: 1.4rem;
            margin-bottom: 1rem;
            color: var(--color-white);
            @media all and (max-width: 768px), all and (max-height: 600px) {
              font-size: 1.2rem;
              margin-bottom: 1rem;
            }

            @media all and (max-width: 425px) {
              font-size: 1rem;
              margin-bottom: 0.875rem;
            }

            a {
              text-decoration: none;
              color: var(--color-white);
            }
          }
        }
      }
      .nav-links {
        padding: 0;
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        li {
          list-style: none;
          margin-bottom: 50px;
          @media all and (max-width: 768px) {
            margin-bottom: 25px;
          }

          @media all and (max-width: 425px) {
            margin-bottom: 20px;
          }

          a {
            font-weight: 600;
            font-size: 1.6rem;
            text-decoration: none;
            color: var(--color-white);
            cursor: pointer;
            @media all and (max-width: 768px) {
              font-size: 1.4rem;
            }

            @media all and (max-width: 425px) {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
