.hero-section {
  background-color: var(--color-white);
  position: relative;
  visibility: hidden;

  .row {
    flex-direction: column;
    justify-content: center;

    h4 {
      transform: translateY(-50px);
      font-size: 1.5rem;
      font-weight: 500;
      z-index: 10;
      color: var(--color-black);
      margin: 0;
      @media all and (max-width: 768px) {
        font-size: 1.2rem;
      }
      @media all and (max-width: 425px) {
        margin-top: 72px;
        font-size: 1rem;
      }
      .line {
        margin-bottom: 8px;
        height: 56px;
        position: relative;
        overflow: hidden;
        @media all and (max-width: 768px) {
          height: 48px;
        }
        @media all and (max-width: 425px) {
          height: 32px;
        }
        span {
          position: absolute;
        }
      }
    }

    h2 {
      transform: translateY(-50px);
      font-size: 3rem;
      line-height: 4.2rem;
      font-weight: 700;
      z-index: 10;
      color: var(--color-black);
      margin: 0;
      @media all and (max-width: 1366px) {
        font-size: 2.7rem;
        line-height: 3.7rem;
      }
      @media all and (max-width: 1000px) {
        font-size: 2.3rem;
        line-height: 3rem;
      }
      @media all and (max-width: 850px) {
        font-size: 2rem;
      }
      @media all and (max-width: 600px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
      @media all and (max-width: 425px) {
        font-size: 1.5rem;
      }
      @media all and (max-width: 375px) {
        font-size: 1.2rem;
      }

      .line {
        margin-bottom: 8px;
        height: 56px;
        position: relative;
        overflow: hidden;
        @media all and (max-width: 768px) {
          height: 48px;
        }
        @media all and (max-width: 600px) {
          height: 32px;
        }
        span {
          position: absolute;
        }
      }
    }
  }
}
