.footer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .page-suivante {
      font-size: 4rem;
      font-weight: 700;
      color: var(--color-black);
      margin-bottom: 32px;
      @media all and (max-width: 768px) {
        font-size: 2.8rem;
      }
      @media all and (max-width: 425px) {
        font-size: 2.2rem;
      }
    }

    .btn-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 2;

      .btn-text {
        color: var(--color-black);
        font-size: 1.6rem;
        font-weight: 600;
        margin-right: 16px;

        @media all and (max-width: var(--breakpoint-tablet)) {
          font-size: 1.3rem;
        }
        @media all and (max-width: var(--breakpoint-phone)) {
          font-size: 1.1rem;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
