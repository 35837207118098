.white-intro-overlay {
  background-color: var(--color-white);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.black-intro-overlay {
  .top {
    position: absolute;
    width: 100%;
    z-index: 8;
    .overlay-top {
      position: absolute;
      height: 100%;
      width: 33.333vw;
      background: var(--color-black);
      bottom: 0;
      left: 0;
      right: 0;
      @media all and (max-width: 768px) {
        width: 100vw;
      }
      &:nth-child(2) {
        left: 33.333%;
        @media all and (max-width: 768px) {
          display: none;
        }
      }
      &:nth-child(3) {
        left: 66.666%;
        @media all and (max-width: 768px) {
          display: none;
        }
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 8;
    .overlay-bottom {
      position: absolute;
      height: 100%;
      width: 33.333vw;
      background: var(--color-black);
      bottom: 0;
      right: 66.666%;
      @media all and (max-width: 768px) {
        right: 0;
        width: 100vw;
      }
      &:nth-child(2) {
        right: 33.333%;
        @media all and (max-width: 768px) {
          width: 100vw;
          top: 100%;
          right: 0;
        }
      }
      &:nth-child(3) {
        right: 0;
        @media all and (max-width: 768px) {
          width: 100vw;
          top: 200%;
        }
      }
    }
  }
}
