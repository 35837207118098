@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,500,600,700&display=swap");

:root {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-green: #73a580; //CAD5CA 9DDBAD C3E8BD 169873 73A580

  --breakpoint-phone: "425px";
  --breakpoint-tablet: "768px";
  --breakpoint-desktop: "1366px";
  --breakpoint-LGdesktop: "1920px";
}

body,
html {
  user-select: none;
  background: var(--color-white);
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar,
.fake-body::-webkit-scrollbar {
  display: none;
}

body,
.fake-body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.fake-body {
  overflow-x: hidden;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;
  @media all and (max-width: 1560px) {
    width: 1280px;
    min-width: 1280px;
  }
  @media all and (max-width: 1366px) {
    width: 1080px;
    min-width: 1080px;
  }
  @media all and (max-width: 1080px) {
    width: 100%;
    min-width: 100%;
  }

  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;
    @media all and (max-width: var(--breakpoint-phone)) {
      padding: 0 16px;
    }
  }
  .v-center {
    align-items: center;
  }
  .space-between {
    justify-content: space-between;
  }
}
