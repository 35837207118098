.circled-arrow {
  color: var(--color-black);
  border: 2px solid var(--color-black);
  padding: 12px;
  height: 18px;
  width: 18px;
  border-radius: 100px;
  transition: 0.4s ease-in-out;
  @media all and (max-width: 1366px) {
    padding: 8px;
    width: 16px;
    height: 16px;
  }
  @media all and (max-width: 768px) {
    padding: 6px;
    width: 14px;
    height: 14px;
  }
  @media all and (max-width: 425px) {
    padding: 4px;
    width: 12px;
    height: 12px;
  }
}

.on-hover {
  cursor: pointer;
  &:hover {
    background: var(--color-black);
    border-color: var(--color-black);
    color: var(--color-white);
  }
}
