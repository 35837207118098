.scroll-helper {
  position: absolute;
  right: calc((100vw - 1560px) / 2 + 36px);
  bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
  @media all and (max-width: 1560px) {
    right: calc((100vw - 1280px) / 2 + 36px);
  }
  @media all and (max-width: 1366px) {
    right: calc((100vw - 1080px) / 2 + 36px);
  }
  @media all and (max-width: 1080px) {
    right: 36px;
  }

  .scroll-text {
    color: var(--color-black);
    font-size: 1.5rem;
    font-weight: 400;
    margin-right: 16px;
    @media all and (max-width: 1366px) {
      font-size: 1.3rem;
      margin-right: 14px;
    }
    @media all and (max-width: 425px) {
      font-size: 1.1rem;
      margin-right: 12px;
    }
  }
}
